<template>
  <v-container fluid> </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "EmailVerify",

  created() {
    this.authVerify({
      id: this.$route.params.id,
      hash: this.$route.params.hash,
      expires: this.$route.query.expires,
      signature: this.$route.query.signature,
    })
      .then(() => {
        this.alertSuccess(this.$t("Auth.emailVerified"));
        this.$router.replace({ name: "home" });
      })
      .catch((e) => this.error(e));
  },

  methods: {
    ...mapActions({
      authVerify: "auth/verify",
      alertError: "app/alertError",
      alertSuccess: "app/alertSuccess",
    }),

    error(e) {
      let message = "";

      if (this.$helper.error(e) == "Invalid signature.") {
        message = this.$t("Auth.invalidSignature");
      } else {
        message = this.$t("Auth.verifyError") + this.$helper.error(e);
      }

      this.alertError(message);
    },
  },
};
</script>
